import React from "react";
import { Link, NavLink } from "react-router-dom";

function Desktop({ list = [], path }) {
  return (
    <div className="uk-flex uk-flex-column uk-position-relative uk-visible@l">
      <div className="uk-position-top-left uk-position-z-index uk-animation-fade">
        <Link to="/" className="uk-logo">
          <img
            className="lazyload"
            width={390}
            data-src="/kutter-logo.png"
            alt="Kutter Logo"
          />
        </Link>
      </div>
      <nav
        className="uk-navbar-container uk-navbar-transparent uk-animation-slide-top uk-navbar uk-section-muted"
        data-uk-navbar=""
      >
        <div className="uk-navbar-right uk-container uk-container-expand-right">
          <ul className="uk-navbar-nav">
            {list.map((child) => (
              <li key={child._id}>
                <NavLink
                  to={(child.page && child.page.url) || child.url}
                  activeClassName="uk-active"
                  isActive={() => path.includes(child._id)}
                >
                  {child.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </nav>
      <section className="uk-section uk-section-small">
        <div className="uk-container uk-container-expand">
          <h4 className="uk-flex uk-flex-right uk-text-secondary uk-text-right">
            Qualität und Innovation.
            <br />
            aus Tradition.
          </h4>
        </div>
      </section>
    </div>
  );
}

export default Desktop;
